.container {
    position: absolute;
    top: 120px;
    left: 250px;
    max-width: calc(100% - 245px);
    flex-grow: 1;
    padding: 20px;
    display: flex;
    max-height: 50%;
}

.paper-1 {
    position: relative;
    background-color: #27293D;
    height: 400px;
    padding-bottom: 5px;
}
