body {
    background-color: #1d1e2f;
}

.logo {
    display: flex;
    height: 80px;
    align-self: flex-start;
    margin-left: 90px;
    margin-bottom: 10px;
}


.contentContainer {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    padding: 15px;
    margin: auto;
}


.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 380px;
    width: 350px;
    background-color: #272945;
    border-radius: 4px;
    border-style: solid;
    border-color: #313452;
}

 .textFieldUsername {
    position: relative !important;
    top: 50px !important;
    color: #fff !important;
    font-weight: 450 !important;
    width: 250px !important;   
}

.textFieldUsernameInput {
    background-color: rgb(39, 41, 61) !important;
    color: rgb(0, 225, 255) !important;
    font-weight: 450 !important;
}

.textFieldPassword {
    color: #fff !important;
    font-weight: 450 !important;
    width: 250px !important;   
    
}

.textFieldPasswordInput {
    background-color: rgb(39, 41, 61) !important;
    color: rgb(0, 225, 255) !important;
    font-weight: 450 !important;
}

.textFieldLabel {
    color: rgb(0, 225, 255) !important;
}

.LoginButton {
    position: relative;
    top: -50px;
}