.body-container {
    background-color: #313452;
}

.h1-context {

    color: #ffffff;
    position: relative;
    margin: auto;
    width: 50%;
    font-size: 72px;
}