@import '../../scss/spacing-function-style';
@import '../../scss/theme-primary-colors';

:export {
  textColor: $text-color !important;
  helperTextColor: #616172 !important;
}

.rootContent {
    height: 670px !important;
    background-color: $main-background-color !important;
    border:  $primary-border-color solid !important;
  }

.backButton {
    margin-right: themeSpacing(2) !important;
  }

.instructions {
  margin-top: themeSpacing(1) !important;
  margin-bottom: themeSpacing(1) !important;
  color: $text-color !important;
}

.actionButtons {
  position: absolute !important;
  bottom: themeSpacing(1) !important;
  left: themeSpacing(2) !important;
}

.pageContentPaper {
  background-color: #323748 !important;
  margin-left: themeSpacing(3) !important;
  margin-right: themeSpacing(3) !important;
  padding: themeSpacing(1) !important;
}

.stepCircle {
  padding-top: themeSpacing(1)!important;
  background-color: $main-background-color !important;
  color: $text-color !important;
  :global{
    .MuiTypography-root.MuiStepLabel-label.MuiStepLabel-alternativeLabel.MuiTypography-body2.MuiTypography-displayBlock{
      color: #616172
    }
  }
}

.activeLabel{
  color: $text-color !important;
}

.completedLabel {
  color: $text-color !important;
}

.backButtonHidden{
  visibility: hidden !important;
}