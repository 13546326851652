.breadcrumbsStyle {
    position: relative !important;
    top: 100px !important;
    left: 270px !important;
    color: #A7A7A7 !important;
    max-width: fit-content !important; 
}

.breadcrumbsLinkText {
    color: #5c5c5c !important;
    text-decoration: none !important;
    cursor: pointer !important;
}