.appBar {
    position: fixed !important;
    min-height: 100px !important;
}

.imageStyle {
    position: relative !important;
    height: 70px !important;
    margin: 12px auto 0px auto !important;
}

.tooltipWindow {
    position: absolute !important;
    margin-right: 30px !important;
}

.menuWindow{
    background-color: #f5f5f5 !important;
    min-width: 200px !important;
    &:hover{
        background-color: #c5c5c5 !important;
    }
}

.interiorIconList {
    margin-right: 0px;
    margin-left: auto;
}

.inputFile {
    display: none;
}