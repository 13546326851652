@import '../../scss/theme-primary-colors';


.antSwitch {
    color: #f20075 !important;
    }
    
.antSwitchBase {
    color: #333333 !important;
}


