@import '../../../../scss/spacing-function-style';
@import '../../../../scss/theme-primary-colors';
  

:export {
  textColor: $text-color !important;
  helperTextColor: $secondary-pink;
  helperTextColorDisabledField: #616172;
}

  
.menuItem {
      background-color: #f5f5f5 !important;
      &:hover {
        background-color: $blue-hover !important;
        color: #fff !important;
      }
}
    
.selectedItem {
        background-color: #b1b1b1 !important;
        &:hover {
          background-color: $blue-hover !important;
          color: #fff !important;
        }
}

.dialogTitle{
      color: $text-color;
      margin-bottom: themeSpacing(2) !important;
}

.dialogBox {
    background-color: $main-background-color ;
    border:  $primary-border-color solid !important;
}

.textFieldStyle {
  color: $text-color !important;
  background-color: $secondary-background-color;
}

.disabledFields{
  margin-top: themeSpacing(3) !important;
}

.textFieldStyleDisabled {
  color: #616172 !important;
}

.actionButtons {
  margin-top: themeSpacing(2) !important;
  float: right;
}