@import '../../../../scss/spacing-function-style';
@import '../../../../scss/theme-primary-colors';


:export {
    textColor: $text-color !important;
    helperTextColor: $secondary-pink;
    helperTextColorDisabledField: #616172;
}

.dialogTitle{
    color: $text-color;
    margin-bottom: themeSpacing(2) !important;
}

.dialogBox {
    background-color: $main-background-color ;
    border:  $primary-border-color solid !important;
}

.actionButtons {
    margin-top: themeSpacing(4) !important;
    float: right;
}

.textFieldStyle {
    color: $text-color !important;
    background-color: $secondary-background-color;
}

.disabledFields{
    margin-top: themeSpacing(3) !important;
}

.textFieldStyleDisabled {
    color: #616172 !important;
    
}