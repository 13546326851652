@import '../../scss/spacing-function-style';
@import '../../scss/theme-primary-colors';


$text-disabled-color: #616172;

:export {
    textColor: $text-color !important;
    disabledTextColor: $text-disabled-color
  }

.readOnlyContentsContainer {
    border: 1.5px dashed #808080 !important;
    margin-top: themeSpacing(1) !important;
    margin-left: themeSpacing(2) !important;
    margin-right: themeSpacing(2) !important;
    padding: themeSpacing(1) !important;
    background-color: #27293d !important;
  }

