@import '../../scss/spacing-function-style';
@import '../../scss/theme-primary-colors';


:export {
  textColor: $text-color !important;
  helperTextColor: #616172 !important
}

.dialogContainer {
    padding: themeSpacing(1) !important;
    height: 580px;
    background-color: $main-background-color;
    color: $text-color;
    border: 3px solid rgb(53, 58, 83);
  }

.paperContainer {
    margin-right: themeSpacing(2) !important;
    margin-left: themeSpacing(2) !important;
    padding: themeSpacing(1) !important;
    background-color: #323748 !important;
    width: auto !important;
    color: $text-color !important;
    :global {
      .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense {
        color: #A7A7BA !important;
    }
      .MuiFormHelperText-root.MuiFormHelperText-marginDense.MuiFormHelperText-contained {
        color: #616172 !important;
    }
      .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled.MuiFormHelperText-marginDense {
        color: #f54242 !important;
    }
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense{
      background-color: $main-background-color !important;
    }
  }
}

.actionButtons {
  float: right;
  margin-top: themeSpacing(5);
}

.menuItem {
  &:hover {
    background-color: #3F51B5 !important;
    color: #fff !important;
  }
}

.selectedItem {
  background-color: #b1b1b1 !important;
  &:hover {
    background-color: #3F51B5 !important;
    color: #fff !important;
  }
}