.container {
    position: absolute;
    top: 120px;
    left: 250px;
    padding: 20px;
    max-height: 50%;
}

.text {
    font-family: verdana;
    font-size: 20px;
    color: white;
}
