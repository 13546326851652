@import '../../scss/spacing-function-style';
@import '../../scss/theme-primary-colors';


:export {
  textColor: $text-color !important;
  helperTextColor: #616172;
  helperTextColorDisabledField: #616172;
  blackColor: #000
}

.root {
  margin-bottom: themeSpacing(1);
}

.menuItem {
  &:hover {
    background-color: #3F51B5 !important;
    color: #fff !important;
  }
}

.selectedItem {
  background-color: #b1b1b1 !important;
  &:hover {
    background-color: #3F51B5 !important;
    color: #fff !important;
  }
}

.datePicker {
  margin-top: themeSpacing(1) ;
}

.textFieldStyle {
  color: $text-color !important;
  background-color: $secondary-background-color;
}

.textFieldDisabled{
  color: #000 !important;
}