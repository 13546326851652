.container { 
    position: absolute ; 
    top: 100px ; 
    left: 250px ; 
    height: 100vh ; 
    max-width: calc(100% - 245px) ; 
    flex-grow: 1 ; 
    padding: 20px ; 
    display: flex ; 
}

.drawerPaper  {
    width: 250px; 
    background-color: #27293D !important; 
    margin-top: 100px ; 
    border-top: solid 1px #353A53 ; 
}
.drawerAttributer { 
    top: 20px; 
    font-size: 14px !important; 
    color: #A7A7BA !important; 
    border: 0.5px solid #313452 !important; 
    margin-bottom: 5px !important; 
    border-radius: 0px 10px 10px 0px !important; 
    width: 248px !important; 
}

.drawerAttributer:hover { 
    background-color: #3366ff !important; 
    color: #ffff !important; 
}

.extendedTab { 
    top: 20px !important; 
    font-size: 14px !important; 
    color: #A7A7BA !important; 
    border-right: 0.5px solid #313452 !important; 
    border-top: 0.5px solid #313452 !important; 
    margin-bottom: 5px !important; 
    border-radius: 0px 10px 0px 0px !important; 
    width: 248px !important; 
}

.extendedTab:hover { 
    background-color: #3366ff !important; 
    color: #ffff !important; 
    border-radius: 0px 10px 10px 0px !important; 
}

.nested { 
    padding-left: 24px !important; 
    margin: 32px !important; 
    border-left: 3px  solid #313477 !important; 
    border-radius: 0px 10px 10px 0px !important; 
    color: #A7A7BA !important; 
}

.nested:hover { 
    background-color: transparent !important; 
    color: #3366ff !important; 
}

.collapseArea { 
    border-right: 0.5px solid #313452; 
    border-bottom: 0.5px dashed #313452; 
    border-radius: 0px 0px 10px 0px; 
    width: 247px; 
}

.arrowButton:hover { 
    background-color: #bdbdff; 
    color: #000; 
    border-radius: 20px; 
}
