@import '../../scss/theme-primary-colors';
@import '../../scss/spacing-function-style';



$primary-background-chart: rgba(46,255,31, 0.3);
$primary-border-color: rgba(46,255,31, 1);
$secondary-background-color: rgba(200,200,200,0.2);
$secondary-border-color: rgba(215,215,215,1);

$pie-backgroundColor-1: rgba(11, 3, 255, 0.3);
$pie-backgroundColor-2: rgba(0,136,255, 0.3);
$pie-backgroundColor-3: rgba(0,186,255, 0.3);
$pie-backgroundColor-4: rgba(0,226,174, 0.3);
$pie-backgroundColor-5: rgba(46,255,31, 0.3);

$pie-borderColor-1: rgb(11, 3, 255);
$pie-borderColor-2: rgb(0,136,255);
$pie-borderColor-3: rgb(0,186,255);
$pie-borderColor-4: rgb(0,226,174);
$pie-borderColor-5: rgb(46,255,31);


:export {
    primaryBackgroundColor: $primary-background-chart;
    primaryBorderColor: $primary-border-color;
    secondaryBackgroundColor: $secondary-background-color;
    secondaryBorderColor: $secondary-border-color;
    primaryPaperBackgroundColor: $main-background-color;

    pieBackGroundColor1: $pie-backgroundColor-1;
    pieBackGroundColor2: $pie-backgroundColor-2;
    pieBackGroundColor3: $pie-backgroundColor-3;
    pieBackGroundColor4: $pie-backgroundColor-4;
    pieBackGroundColor5: $pie-backgroundColor-5;

    pieBorderColor1: $pie-borderColor-1;
    pieBorderColor2: $pie-borderColor-2;
    pieBorderColor3: $pie-borderColor-3;
    pieBorderColor4: $pie-borderColor-4;
    pieBorderColor5: $pie-borderColor-5
}


.paperGraph {
    position: relative;
    height: 400px;
    padding-bottom: themeSpacing(1);
}