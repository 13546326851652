@import '../../scss/spacing-function-style';
@import '../../scss/theme-primary-colors';



:export {
    textColor: $text-color !important;
    helperTextColor: #616172 !important
}

.checkboxTextStyle{
    color: $text-color !important
}