.inputFileDisplay {
    display: none;
}

.paginationOrgTable.MuiTablePagination-root {
    color: #A7A7BA;
    border-top: 1px #A7A7BA solid;
    font-size: 14px;
}

